import React, { Fragment, useState, useRef } from 'react';
import Progress from './Progress';
import axios from 'axios';
import {ServerConfig} from 'config'

import {
    Button,
    Grid,
    Snackbar
  } from '@material-ui/core';

const FileUpload = (props) => {
  const {uploadUrl, currentUrl, token, onChangeCallback, accept_types} = props;

  const [file, setFile] = useState('');

  const [filename, setFilename] = useState(props.currentUrl);
  const [uploadedFile, setUploadedFile] = useState(props.currentUrl);
  
  const [message, setMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const fileInput = useRef(null)

  const onSubmit = async e => {
    e.preventDefault();
    setShowProgressBar(true);

    const formData = new FormData();
    formData.append('file', file);
    console.log("FOR: ", formData);

    try {
      {/* const url = `${ServerConfig.SERVER_URL}/blog/UploadImage`; */}
      const url = `${ServerConfig.SERVER_URL}${uploadUrl}`;
      console.log("========= URL: "+ url);

      const res = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization':`Bearer ${token}`
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      
      // Clear percentage
      setTimeout(() => setUploadPercentage(0), 10000);

      setUploadedFile(res.data.location);
      setShowProgressBar(false);

      onChangeCallback(res.data.location);
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        setMessage('There was a problem with the server');
      } else {
        setMessage(err.response.data.msg);
      }
      setUploadPercentage(0)
      setShowProgressBar(false);
    }
  };

  return (
    <>
        {message && 
        <Snackbar 
          open={message} 
          onClose={() => setMessage('')}
          message={message} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          ContentProps={{style: {backgroundColor: '#333'}}}
          />
      }

      
      <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className='custom-file mb-4'>
            <input
                type='file'
                className='custom-file-input'
                id='customFile'
                accept={accept_types}
                onChange={onChange}
                ref={fileInput}
                style={{display:'none'}}
            />
            <Button onClick={() => fileInput.current.click()}>Choose File</Button>
            <label className='custom-file-label' htmlFor='customFile' style={{marginLeft:'10px'}}>
                {filename}
            </label>
            </div>
            </Grid>
        
            {filename != "Choose File" &&
                <>
                <Grid item xs={6}>
                    <Button type='submit' value='Upload'>Upload</Button>
                </Grid>
                </>
            }

            {showProgressBar &&
                <Grid item xs={12}>
                    <Progress percentage={uploadPercentage} />
                </Grid>
            }
        </Grid>
      </form>
    </>
  );
};

export default FileUpload;
