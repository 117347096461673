import React, { useState, useEffect} from 'react'
import { Route, Redirect } from 'react-router-dom'
import {ServerConfig} from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';

import _, { get } from 'lodash'


const useStyles = makeStyles((theme) => ({
  darkgreen: {
      color: 'darkgreen'
  },
  red: {
    color: 'red'
  },
  orange: {
    color: 'orange'
  }

}))

const Logs = () => {
  console.log("[AdminLogsPage] Loading!")
  const token = useSelector(state => state.auth.access_token);
  const [numlines, setNumlines] = useState(100)
  const [loglevels, setLoglevels] = useState("")
  const [searchword, setSearchword] = useState("")
  const [logs, setLogs] = useState([])
  const classes = useStyles()

  const available_loglevels = [{name: "DEBUG"},{name: "INFO"},{name: "WARNING"},{name: "ERROR"},]

  const [checkedState, setCheckedState] = useState(
    new Array(available_loglevels.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    const selected_loglevels = updatedCheckedState.reduce(
      (string, currentState, index) => {
        if (currentState === true) {
          return string + available_loglevels[index].name + ",";
        }
        return string;
      },
      ""
    );

    console.log(selected_loglevels);
    setLoglevels(selected_loglevels);
  };

  const handleOnChangeSearchword = (event) => {
    setSearchword(event.target.value);
  }

  const handleOnChangeNumlines = (event) => {
    setNumlines(event.target.value);
  }

  async function fetchLogList(token){
      const config = {headers:{'Content-Type':'application/json'}}

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      let results;

      if(numlines != -1 && loglevels == "" && searchword == ""){
        results = await axios.get(`${ServerConfig.SERVER_URL}/admin/logs/${numlines}`, config)
      }else if(numlines != -1 && loglevels != "" && searchword == ""){
        results = await axios.get(`${ServerConfig.SERVER_URL}/admin/logs/${numlines}/${loglevels}`, config)
      }else if(numlines != -1 && loglevels != "" && searchword != ""){
        results = await axios.get(`${ServerConfig.SERVER_URL}/admin/logs/${numlines}/${loglevels}/${searchword}`, config)
      }else{
        results = await axios.get(`${ServerConfig.SERVER_URL}/admin/logs`, config)
      }
      setLogs(results.data)
    }

  useEffect(() => {
        fetchLogList(token);  // initial fetch

        const interval = setInterval(() => { fetchLogList(token);}, 5*60*1000); // 5 minutes
        return () => clearInterval(interval);
  }, [numlines, loglevels, searchword, token])

  if(!token){
    return <Redirect to="/login" />
  }


	return (
		<>
      <Grid container spacing={3}>
        <Grid xs={6}>
            {available_loglevels.map(({ name }, index) => {
                  return (<>
                          <label htmlFor={`custom-checkbox-${index}`} style={{'margin-right':'5px'}}>{name}</label>
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={name}
                            value={name}
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(index)}
                            style={{'margin-right':'10px'}}
                          />
                          </>
                  );
                })}
          </Grid>
          <Grid xs={3}>
            <input type="text" value={searchword} onChange={handleOnChangeSearchword} style={{'width':'90%'}} />
          </Grid>
          <Grid xs={3}>
            <input type="text" value={numlines} onChange={handleOnChangeNumlines} style={{'width':'90%'}} />
          </Grid>
      
          <Grid xs={12}>
        {_.map(logs, l => {
            if(l.lvl == 'ERROR' || l.lvl == 'CRITICAL'){
                return <Typography className={classes.red}><small>[{l.dt}]</small> {l.lvl} {l.nm} {l.msg}</Typography>
            }else if(l.lvl == 'WARNING'){
                return <Typography className={classes.orange}><small>[{l.dt}]</small> {l.lvl} {l.nm} {l.msg}</Typography>
            }else if(l.lvl == 'INFO'){
                return <Typography className={classes.darkgreen}><small>[{l.dt}]</small> {l.lvl} {l.nm} {l.msg}</Typography>
            }else{
                return <Typography><small>[{l.dt}]</small> {l.lvl} {l.nm} {l.msg}</Typography>
            }
        })}
        </Grid>
        </Grid>
		</>
		)
}

export default Logs
