import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer'
import Marker from './Marker';
import Legend from './Legend';

const LocationsMap = ({ locations, center, zoom, height, popoverSize }) => {
  const handleApiLoaded = (map, maps) => {
    const legend = document.getElementById('legend');
    map.controls[maps.ControlPosition.LEFT_TOP].push(legend);

    const markers = [];

    locations.map((location, index) => (
        markers.push(new maps.Marker({position: {lat: location.lat, lng: location.lng,}, map,}))
        ))

    new MarkerClusterer(map, markers, {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });
  };

  return (
    <div style={{ height: `${height}`, width: '100%' }} data-cy="locations-map">
      <GoogleMapReact
        bootstrapURLKeys={{
          //key: 'AIzaSyADxFGc2Gec58t95waF6bcpgiU5tpPyuGM',
          key: 'AIzaSyCP9RQEpfzc62aSzpi_RGSpUcd_HGpcbMI'
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        hoverDistance={20}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}

      >
      </GoogleMapReact>
    </div>
  );
};

export default LocationsMap;
