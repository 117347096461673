import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { ServerConfig } from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '../../components/EntryListAndEdit/List'
import PaginatedList from '../../components/PaginatedList/PaginatedList'
import FileUpload from '../PublicRelations/FileUpload.js'


import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

const evt = new Event('customertable_is_dirty');

const useStyles = makeStyles((theme) => ({}))

const BagStickerMap = () => {
  const auth = useSelector(state => state.auth)
  const editable_fields = [];

  return (
    <>
        <FileUpload
            uploadUrl='/trackingcode/bagpacksandstickers/upload'
            currentUrl=""
            onChangeCallback={(location) => {window.dispatchEvent(evt)}}
            token={auth.access_token}
            accept_types='.csv'
            />
        {/* /bagpacksandstickers/upload */}
        <PaginatedList
            list_get_url={`${ServerConfig.SERVER_URL}/trackingcode/bagpacksandstickers/`}
            //detail_get_url={`${ServerConfig.SERVER_URL}/trackingcode/bagpacksandstickers/`}
            //detail_put_url={`${ServerConfig.SERVER_URL}/trackingcode/bagpacksandstickers/`}

            column_names={["bagcode", "stickercode"]}
            header_names={["Bag Code", "Sticker Code"]}

            optional_column_names={[]}
            optional_header_names={[]}

            editable_fields={editable_fields}

            //ledger_get_url='/ledger/customer/'
            //ledger_allow_entries={true}
        />
    </>
  )
}

export default BagStickerMap
