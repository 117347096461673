import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))

const Waitlist = () => {
  console.log("[AdminCustomerPage] Loading!")
  const auth = useSelector(state => state.auth)
  const [lists, setLists] = useState([])
  const [listentries, setListentries] = useState([])
  const classes = useStyles()
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchWaitList = async () => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/contact/list`, config)
      setLists(results.data.lists)
      setListentries(results.data.listentries)
    }

    fetchWaitList()
  }, [])

  if(!auth.access_token){
    return <Redirect to="/login" />
  }


	return (
		<>
		    <Typography variant="h2">
                {lists.length} Waitlists:
        </Typography>

        {_.map(lists, l => {
          return <><Typography variant="h3">{l.name} ({l.description})</Typography>
                  <ListGroup className={classes.listGroup}>
                    {_.map(listentries, le => {
                      if(le.id_waitlist == l.id){
                        return <ListGroupItem key={le.email} className={classes.listItem}>{le.email}</ListGroupItem>
                      }
                    })}
                  </ListGroup>
          </>
        })}
          
          
          
			
		</>
		)
}

export default Waitlist