import {
  LOGIN_SUCCESS,
  REFRESH_SUCCESS,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  USER_LOADING,
  ALERT_FAIL,
  ALERT_SUCCESS
} from './types'
import { ServerConfig } from 'config'
import { configureConfigHeader } from 'utils/utils.js'
import axios from 'axios'

export const refresh = (original_request) => (dispatch, getState) => {
  console.log("---- Refresh called with refresh_token: ")
  console.log(getState().auth.refresh_token)

  const token = getState().auth.refresh_token
  if(token){
	  const config = {
	    headers: {
	      'Content-Type': 'application/json'
	    }
	  }

	  if (token) {
	    config.headers.Authorization = `Bearer ${token}`
	  }

	  axios
		.post(`${ServerConfig.SERVER_URL}/auth/refresh`, JSON.stringify({}), config)
		.then((res) => {
			console.log("------ Token refresh")
			console.log(res.data.access_token)
			dispatch({type: REFRESH_SUCCESS, payload: res.data})

			original_request.config.headers['Authorization'] = 'Bearer ' + getState().auth.access_token;
			original_request.config.baseURL = undefined;

			return axios.request(original_request.config);
		})
		.catch((err) => {
		    console.log(err);
		    dispatch(logout());
        })
  }else{
	  dispatch(logout());
	  return Promise.reject(original_request);
  }

}

export const login = (email, password) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING
  })
  const config = configureConfigHeader(getState)
  const body = JSON.stringify({ email, password })
  axios
    .post(`${ServerConfig.SERVER_URL}/auth/`, body, config)
    .then((res) => {
      console.log("-----------------------")
      console.log(res.data)
      console.log("-----------------------")

      if(res.data.permissions === undefined){
        dispatch({
          type: ALERT_FAIL,
          payload: 'No permission'
        })
      }else{
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: ALERT_FAIL,
        payload: 'Incorrect credentials.'
      })
    })
}

export const loadUserFromStorage = () => (dispatch) => {
  console.log('Checking if user data in local storage')
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'))
    console.log('Found a user in local storage')
    console.log(storedUser)
    if (storedUser.user.access_token) {
      console.log('The user has an access token!')
      dispatch({
        type: LOAD_USER_FROM_STORAGE,
        payload: storedUser
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT
  })
}
