import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'assets/scss/material-kit-react.scss'
import { loadUserFromStorage, logout, refresh } from 'redux/actions/auth'
import axios from 'axios'
import Routes from 'routes'

import CustomAlert from 'components/CustomAlerts/CustomAlert.js';

// material ui theme
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core'
import theme_light from './themes/theme_light'
import theme_dark from './themes/theme_dark'


function App() {

  const [theme, setTheme] = useState(theme_dark);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadUserFromStorage());
  }, []);

  // intercept all requests and look for a 401 (unauthorized)
  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
	  if(err.response){
	    if(err.response.status === 401){
		    console.log("[Interceptor] YOU ARE UNAUTHORIZED!")
		    dispatch(refresh(err))
	    }	  
	  }
	  return Promise.reject(err);
  })


  function changeTheme(){
      console.log("SWITCHING THEME!");

    if(theme == theme_dark){
        console.log("Switching to light!");
        setTheme(theme_light);
    }else{
        setTheme(theme_dark);
        console.log("Switching to dark!");
    }
  }


  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <CustomAlert />
      <Routes themeswitchfunction={changeTheme} />
    </MuiThemeProvider>
  )
}

export default App
