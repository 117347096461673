import React, { useState, useEffect, useReducer } from 'react'
import { ServerConfig } from 'config'


// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '../../components/EntryListAndEdit/List'

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

const useStyles = makeStyles((theme) => ({}))


const Products = () => {
  const editable_fields = [
    ["name", "Name"],
    ["description", "Description"],
    ["img_uri", "Image URI"],
    ["quantity", "Quantity"],
    ["price_microdollars", "Price"],
    ["shipping", "Shipping"],
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/shop/`}
        detail_get_url={`${ServerConfig.SERVER_URL}/shop/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/shop/`}

        column_names={["id", "name", "description", "img_uri", "quantity", "price_microdollars", "shipping", "edit"]}
        header_names={["ID", "Name", "Description", "Image URI", "Quantity", "Price", "Shipping", ""]}

        editable_fields={editable_fields}
      />
    </>
  )

}

export default Products