import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, Button, List, ListItem } from '@material-ui/core'
import _, { get } from 'lodash'


import loading_gif from '../../assets/img/loading.gif'

const useStyles = makeStyles((theme) => ({
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0,
    borderBottom: '1px solid gray'
  },
}))

const UpcomingPickups = () => {
  const token = useSelector(state => state.auth.access_token)
  const [pickups, setPickups] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles()

    async function fetchCustomerList(token){
      setIsLoading(true);
      const config = {headers:{'Content-Type':'application/json'}}

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/calendar/list`, config)
      setPickups(results.data)
      setIsLoading(false);
    }


  useEffect(() => {
    fetchCustomerList(token);

    const interval = setInterval(() => { fetchCustomerList(token);}, 5*60*1000); // 5 minutes
    return () => clearInterval(interval);
  }, [token])

  if(!token){
    return <Redirect to="/login" />
  }

  /*
   * extract all single pickups for a specificid date
   * generate a csv-string
   * make csv-string downloadable as a csv-file
   */
  function export_csv(date, return_only_confirmed){
    console.log("Exporting CSV for date "+date);
    // get all pickups for that date
    let csv_string = "";

    const sep = ";";
    let thesepickups = pickups[date];
    _.map(thesepickups, sp => {
        if(
            (return_only_confirmed && (sp.note.includes('(Confirmed)') || sp.note.includes('Dropoff'))) ||
            !return_only_confirmed
        ){
              csv_string += sp.first_name + sep + sp.last_name + sep;
              csv_string += sp.zipcode + sep + sp.city + sep;
              csv_string += sp.housenumber + sep + sp.street;
              csv_string += "\n";
        }
    })

    // make downloadable file from this
    const element = document.createElement("a");
    const file = new Blob([csv_string], {type: 'text/plan'});
    element.href = URL.createObjectURL(file);
    element.download = "route_"+date+".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (
    <>
      <Typography variant="h2">
        Next pickups:
      </Typography>

      {isLoading &&
	      <>
	      <div style={{'width':'100%', 'text-align':'center'}}>
		<img src={loading_gif} style={{'width':'150px'}}/>
	      </div>
	      </>
      }

      {!isLoading &&
	<>
        {_.map(pickups, (p,date) => {
            return <>
              <Typography variant="h3">{date}: {p.length} pickups &nbsp;&nbsp; 
              <Button onClick={() => { export_csv(date, false) }} style={{height:'1em'}}>Export</Button>&nbsp;&nbsp;
              <Button onClick={() => { export_csv(date, true) }} style={{height:'1em'}}>Export Confirmed Only</Button>
              </Typography>
                    <List>
                    {_.map(p, single_pickup => {
                        {
                            if(single_pickup.note.includes('(Confirmed)') ||
                            single_pickup.note.includes('Dropoff')){
                                return <ListItem className={classes.listItem}>
                                    {single_pickup.first_name} {single_pickup.last_name},&nbsp;
                                    {single_pickup.zipcode} {single_pickup.city},&nbsp;
                                    {single_pickup.housenumber} {single_pickup.street}&nbsp;&nbsp;<b>
                                    {single_pickup.note}</b></ListItem>
                            }
                        }
                    }
                    )}

                    {_.map(p, single_pickup => {
                        {
                            if(!single_pickup.note.includes('(Confirmed)') &&
                            !single_pickup.note.includes('Dropoff')){
                                return <ListItem className={classes.listItem}>
                                    {single_pickup.first_name} {single_pickup.last_name},&nbsp;
                                    {single_pickup.zipcode} {single_pickup.city},&nbsp;
                                    {single_pickup.housenumber} {single_pickup.street}&nbsp;&nbsp;
                                    {single_pickup.note}</ListItem>
                            }
                        }
                    }
                    )}
                    </List>
                <br />
              </>
        })
      }
	      </>
      }
    </>
  )
}

export default UpcomingPickups
