import {
  LOGIN_SUCCESS,
  REFRESH_SUCCESS,
  LOGOUT,
  LOAD_USER_FROM_STORAGE,
  USER_LOADING
} from '../actions/types'


const initialState = {
  access_token: null,
  refresh_token: null,
  isAuthenticated: false,
  isLoading: false,
  customer: null,
  permissions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case LOGIN_SUCCESS:
      return {
        access_token: action.payload.access_token,
	    refresh_token: action.payload.refresh_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.customer
        },
        // it is possible to sign in as a customer, but that will result in undefined permissions
        permissions: action.payload.permissions !== undefined ? action.payload.permissions : []
      }

    case REFRESH_SUCCESS:
	return {
		...state,
		access_token: action.payload.access_token
	}

    case LOAD_USER_FROM_STORAGE:
      return {
        access_token: action.payload.user.access_token,
	    refresh_token: action.payload.user.refresh_token,
        isAuthenticated: true,
        isLoading: false,
        customer: {
          ...action.payload.user.customer
        },
        permissions: action.payload.user.permissions
      }

    case LOGOUT:
      localStorage.removeItem('user')
      return {
        access_token: null,
	    refresh_token: null,
        isAuthenticated: false,
        isLoading: false,
        customer: null,
        permissions: []
      }

    default:
      return state
  }
}
