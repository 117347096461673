import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, Modal, Button, Box } from '@material-ui/core'
import { ListGroup, ListGroupItem, Table } from 'reactstrap';

import Form from 'react-bootstrap/Form'

import EditComponent from 'components/EntryListAndEdit/EditComponent.js'
import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const evt = new Event('customertable_is_dirty');
const evt_ledger = new Event('ledger_updated');

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
    label: {
        display:'block',
        width:200
    },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const TenantLedger = () => {
  console.log("[LedgerPage] Loading!")
  const token = useSelector(state => state.auth.access_token)
  const [tenants, setTenants] = useState([])
  const [selectedTenant, setSelectedTenant] = useState(undefined)
  const [ledger, setLedger] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeZone, setTimeZone] = useState("America/New_York");
  const classes = useStyles()
  const dispatch = useDispatch();

  const fetchTenants = async () => {
    const config = {headers:{'Content-Type':'application/json'}}

    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }

    const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/`, config);
    setTenants(results.data)
  }

  const fetchLedger = async () => {
    const config = {headers:{'Content-Type':'application/json'}}

    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }

    let this_startdate = (1900+startDate.getYear())+"-"+(1+startDate.getMonth())+"-"+(startDate.getDate())
    let this_enddate = (1900+endDate.getYear())+"-"+(1+endDate.getMonth())+"-"+(endDate.getDate())

    if(selectedTenant === undefined){ return; }

    const results = await axios.get(`${ServerConfig.SERVER_URL}/ledger/tenant/${selectedTenant}/${this_startdate}/${this_enddate}/${timeZone}/`, config);

	// convert microdollars to dollars
	let USDollar = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
	for (let i in results.data) {
		for(let key in results.data[i]){
			if(key.includes('microdollar') || key.includes('balance')){
				console.log("Recalculating "+key);
				results.data[i][key] = USDollar.format((results.data[i][key] / 1e6).toFixed(2));
			}else if(key == "timestamp"){
			    let thisdate = new Date(results.data[i][key])
				results.data[i][key] = thisdate.toLocaleDateString("en-US", {timeZone: timeZone}) + " " + thisdate.toLocaleTimeString("en-US", {timeZone: timeZone});
			}
		}
	}
          setLedger(results.data)
    }

  window.addEventListener('customertable_is_dirty', fetchLedger);

  useEffect(() => {
    fetchLedger();
  }, [startDate, endDate, timeZone, token, selectedTenant])

  useEffect(() => {
    fetchTenants();
  }, [])

  if(!token){
    return <Redirect to="/login" />
  }

    let col = [
        "timestamp",
        "transactiontype",
        "for_customer_id",
        "for_customer_name",
        "for_tenant_name",
        //"redemption_amount_microdollars",
        //"per_container_fee_total_microdollars",
        //"per_task_fee_total_microdollars",
        //"per_container_recycletek_fee_total_microdollars",
        //"handling_cut_fee_total_microdollars",
        "total_transaction_value_microdollars",
        "new_customer_balance",
        "edit"];

	let tHead = [
	    "Date",
	    "Transactiontype",
	    "Customer",
	    "Customer Name",
	    "Tenant",
		//"RDA",
		//"PCF",
		//"PTF",
		//"PCRF",
		//"HCF",
		"Value",
		"Balance",
		""];

    var non_editable_fields = [];
    let materials = ["aluminum", "glass", "bi-metal", "plastic", "wds-bbp"];
    materials.map(function(material, bla){
        non_editable_fields.push(["count_"+material, "Count "+material, "count"]);
    })

    let submaterials = ["aluminum", "glass", "bi-metal",
                        "pete", "hdpe", "ldpe", "pp", "ps", "pvc",
                        "paperboard-carton", "bag-in-box", "multilayer-pouch",
    ]
    submaterials.map(function(submaterial, bla){
        non_editable_fields.push(["weight_"+submaterial+"_lbs", "Weight "+submaterial, "weight"]);
    })

    let myfields = [
        ["timestamp", "Date", "timestamp"],
        //["transactiontype", "Transactiontype"],
        //["for_customer_name", "Customer Name"],
        ["customer_name", "Customer Name", ""],
        ["total_transaction_value_microdollars", "Value", "currency"],
        ["cashed_out", "Cashed out", ""],
        ["additional_info_type", "Add.Info Type", "dont_show_if_empty"],
        ["additional_info_number", "Add.Info #", "dont_show_if_empty"],
        ["additional_info_state", "Add.Info State", "dont_show_if_empty"],
        ["additional_info_signature", "Add.Info Sign.", "b64image"],
        ["task_changes", "Changes", "changelist"],
      ];
      myfields.map(function(entry, bla){
        non_editable_fields.push(entry);
      })

    /*
    // weights are only available per submaterial, i.e. not "plastic", but "hdpe", "ldpe", etc.
    materials.map(function(material, bla){
        non_editable_fields.push(["weight_"+material+"_lbs", "Weight [lbs] "+material]);
    })*/

    console.log("Non-editable-fields:");
    console.log(non_editable_fields);

	return (
		<>
		    <Typography variant="h2">
                {ledger.length} Ledger entries:
            </Typography>

            <Grid container spacing={3}>
                <Grid xs={3} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>

                <Typography variant="h2">
                    Tenant
                  </Typography>
                  <select
                    className="form-select"
                    name="tenant"
                    id="tenant"
                    onChange={(event) => {setSelectedTenant(event.target.value)}}>
                        <option value="">Please Select</option>
                        {
                            tenants.map(function(t, bla){
                                return <option value={t.id}>{t.business_name}</option>
                            })
                        }
                    </select>
                </Grid>

                <Grid xs={3} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
                <Typography variant="h2">
                    Timezone
                  </Typography>
                  <select
                    className="form-select"
                    name="timezone"
                    id="timezone"
                    onChange={(event) => {setTimeZone(event.target.value)}}>
                      <option value="America/New_York">New York</option>
                      <option value="America/Los_Angeles">California</option>
                    </select>
                </Grid>

                <Grid xs={3} style={{'marginBottom': '20px'}}>
                  <Typography variant="h2">
                    Start-Date (incl.):
                  </Typography>
                  <DatePicker selected={startDate} onChange={(date) => {setStartDate(date)}}
                    className="form-select" />
                </Grid>

                <Grid xs={3} style={{'marginBottom': '20px'}}>
                  <Typography variant="h2">
                    End-Date (incl.):
                  </Typography>
                  <DatePicker selected={endDate} onChange={(date) => {setEndDate(date)}}
                    className="form-select" />
                </Grid>
            </Grid>

			<SortableTbl
			    paging={false}
			    defaultCSS={false}
				tblData={ledger}
				tHead={tHead}
				dKey={col}
				search={true}
				customTd={[
                      {
                        custd: (tbldata) => {
                          return <EditComponent
                            detail_get_url={`${ServerConfig.SERVER_URL}/ledger/details/`}
                            detail_delete_url={`${ServerConfig.SERVER_URL}/ledger/delete/`}
                            detail_put_url={undefined}
                            rowData={tbldata.rowData}
                            tdData={tbldata.tdData}
                            editable_fields={[]}
                            non_editable_fields={non_editable_fields}
                            ledger_get_url={undefined}
                            ledger_allow_entries={undefined}
                            show_machine_stats={undefined}
                          />
                        },
                        keyItem: "edit"
                      }
                    ]}
			/>
		</>
		)
}

export default TenantLedger
