import React from 'react'
import axios from 'axios'
import {ServerConfig} from 'config'
import { Route, Redirect } from 'react-router-dom'
import {useSelector} from 'react-redux'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'

import { Link } from 'react-router-dom';
import { getAllowedRoutes } from 'utils';

import Page from 'components/Page/Page'
import Stats from './Stats.js'
import Plots from './Plots.js'

const useStyles = makeStyles((theme) => ({}))



const DashboardPage = (props) => {
    const auth = useSelector(state => state.auth)
    const classes = useStyles()

    async function trigger_weekly_email(){
      const config = {headers:{'Content-Type':'application/json'}}

      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/send_weekly_stats`, config)
    }

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  return (
    <Page title="Dashboard">
        <Container maxWidth={false}>
            <Typography color="primary" variant="h1">
                Dashboard
            </Typography>

            <a href="#" onClick={() => trigger_weekly_email()} >Trigger weekly stats email</a>

            <Grid container spacing={2}>
                {hasPermission(["Admin","admin"]) &&
                    <Stats />
                }

                {hasPermission(["Admin","admin"]) &&
                    <Plots />
                }

          </Grid>
        </Container>
    </Page>
  )
}

export default DashboardPage

DashboardPage.propTypes = {
  children: PropTypes.node
}
