import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  default: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '2px',
    backgroundColor: theme.palette.background.default,
  },
  small: {
    borderRadius: '2px',
    padding: '2px 2px 2px 6px',
    backgroundColor: theme.palette.background.default,
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      fontWeight: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  open: {
    backgroundColor: '#008EAA',
    height: '12px',
    width: '12px',
  },
  closed: {
    backgroundColor: '#FFC845',
    height: '12px',
    width: '12px',
  },
  soon: {
    backgroundColor: '#7C878E',
    opacity: '0.7',
    height: '12px',
    width: '12px',
  },
}));

export default function Legend({ size }) {
  const classes = useStyles();

  return (
    <Box
      id="legend"
      className={size === 'small' ? classes.small : classes.default}
    >
      <Box className={classes.item}>
        <Box className={classes.open} />
        <Typography>Locs Open</Typography>
      </Box>
      <Box className={classes.item}>
        <Box className={classes.closed} />
        <Typography>Locs closed</Typography>
      </Box>
      <Box className={classes.item}>
        <Box className={classes.soon} />
        <Typography>Coming soon</Typography>
      </Box>
    </Box>
  );
}
