import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, CardHeader } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({ }))


const Stats = () => {
    const [stats, setStats] = useState([]);
    const token = useSelector(state => state.auth.access_token);

    async function fetchStats(token){
	    const config = {headers:{'Content-Type':'application/json'}}
      
            if(token){
              config.headers.Authorization = `Bearer ${token}`
            }
      
            const results = await axios.get(`${ServerConfig.SERVER_URL}/business/stats`, config)
            setStats(results.data)
      }
  
    const classes = useStyles()
  
    useEffect(() => {
        fetchStats(token);  // initial fetch

        const interval = setInterval(() => { fetchStats(token);}, 5*60*1000); // 5 minutes
        return () => clearInterval(interval);
    }, [token])
  
    if(!token){
      return <Redirect to="/login" />
    }

  
    return (
      <>
        {_.map(stats, (data, key) => {
            return (
                <Grid item xs={12} sm={6} md={3}>
                  <Card variant="outlined">
                    <CardContent dangerouslySetInnerHTML={{ __html: key+'<hr/>'+data }}></CardContent>
                  </Card>
                </Grid>
            );
          })
        }
      </>
    )
  }
  
  export default Stats
