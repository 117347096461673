import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))

const EcoCube = ({changefun}) => {

  class BaseProductDetailLinkComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    handleClick = (e) => {
      e.preventDefault();
      changefun(this.props.rowData.id)
    }
    
    render() {
      return (
        <td>
          <button onClick={this.handleClick}>Details</button>
        </td>
      );
    }
  }

  console.log("[AdminEcocubePage] Loading!")

  const auth = useSelector(state => state.auth)

  const [ecocubes, setEcocubes] = useState([])

  const classes = useStyles()
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchEcocubeList = async () => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/ecocube/`, config)

      setEcocubes(results.data)
      changefun(results.data[0].id);
    }

    fetchEcocubeList()
  }, [])

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

    let col = ["id", "note", "current_value", "last_contact", "detail"];
	let tHead = ["Id", "Note", "Current Value", "Last Contact", "Detail"];

	return (
		<>
		    <Typography variant="h2">
                {ecocubes.length} Registered Ecocubes:
            </Typography>
			<SortableTbl
			    paging={false}
			    defaultCSS={false}
				tblData={ecocubes}
				tHead={tHead}
				customTd={[
					{ custd: BaseProductDetailLinkComponent, keyItem: "detail" },
				]}
				dKey={col}
				search={true}
			/>
		</>
		)
}

export default EcoCube
