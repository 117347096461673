import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'


// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Box from "@material-ui/core/Box";

// components
import _, { get } from 'lodash'

import Page from 'components/Page/Page'

import PerMachine from './PerMachine.js'
import RTLedger from './RTLedger.js'
import TenantLedger from './TenantLedger.js'

const useStyles = makeStyles((theme) => ({ }))

const AccountingPage = (props) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  return (
    <Page title="Accounting">
        <Container maxWidth={false} >

        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="PerMachine" />
            <Tab label="RT Ledger" />
            <Tab label="Tenant Ledger" />
          </Tabs>
        </AppBar>
        
        <TabPanel value={value} index={0}>
          {hasPermission(["Admin","admin"]) &&
                    <PerMachine />
                }
        </TabPanel>

        <TabPanel value={value} index={1}>
          {hasPermission(["Admin","admin"]) &&
                    <RTLedger />
                }
        </TabPanel>

        <TabPanel value={value} index={2}>
          {hasPermission(["Admin","admin"]) &&
                    <TenantLedger />
                }
        </TabPanel>

        </Container>
    </Page>
  )
}

export default AccountingPage

AccountingPage.propTypes = {
  children: PropTypes.node
}
