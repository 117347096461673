// Importing modules
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';
import {useSelector} from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import {ServerConfig} from 'config'
import FileUpload from './FileUpload.js'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
  label: {
    display:'inline-block',
    width:'300px',
  },
  inputBox: {
    width: '100%',
  }
}))

const example_image_upload_handler = (blobInfo, progress, token) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest(); //retrieves data from url without need of refresh as to not interrupt what someone is doing
  xhr.withCredentials = false;
  xhr.open('POST', `${ServerConfig.SERVER_URL}/blog/UploadImage`); //initialized a new post request to the specified route
  xhr.setRequestHeader('Authorization', `Bearer ${token}`); //verifictation 

  xhr.upload.onprogress = (e) => { //your upload progress
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = () => { //If here, that means the request was sent. Determine its status and show it to user
    if (xhr.status === 403) {
      reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      reject('HTTP Error: ' + xhr.status);
      return;
    }

    const json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      reject('Invalid JSON: ' + xhr.responseText);
      return;
    }

    resolve(`${ServerConfig.SERVER_URL}/${json.location}`); //get the succesfful response
  };

  xhr.onerror = () => {
    reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
});

const AddPost = (props)=>{
    const auth = useSelector(state => state.auth);
    const classes = useStyles();
    const [blogData, setBlogData] = useState({
        title: "",
        description: "",
        header_img: "",
    });

    useEffect(() => {
      if(props.postIdToEdit !== undefined){
        const config = {headers:{'Content-Type':'application/json'}}

        if(auth.access_token){
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }

        const fetchPostInfo = async () =>{
            const response = await axios.get(`${ServerConfig.SERVER_URL}/blog/post/${props.postIdToEdit}`, config);
            setBlogData(response.data);
            console.log(response.data);
        }
        fetchPostInfo();
      }
    }, []);

    //VERIFICATION
    if(!auth.access_token){
      return <Redirect to="/login" />
    }
    const config = {headers:{'Content-Type':'application/json'}}
    if(auth.access_token){
      config.headers.Authorization = `Bearer ${auth.access_token}`
    }

    const handleChange = (e)=>{
        let value = e.target.value;
        const fieldName = e.target.id;
        setBlogData((prevData)=>({...prevData, [fieldName]:value}));
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        try {
            if(props.postIdToEdit !== undefined){
              const response = await axios.post(`${ServerConfig.SERVER_URL}/blog/EditPost`, blogData, config);
              alert(response.data["msg"]);
            }else{
              const response = await axios.post(`${ServerConfig.SERVER_URL}/blog/AddPost`, blogData, config);
              alert(response.data["msg"]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    console.log(blogData);
    return(
      <>
        <Typography variant="h2">
          Create Blog Post
        </Typography>

        <label className={classes.label}>Upload new header image</label>
          <FileUpload
            uploadUrl='/blog/UploadImage'
            currentUrl={blogData['header_img']}
            onChangeCallback={(location) => {setBlogData((prevData)=>({...prevData, ['header_img']: location}))}} 
            token={auth.access_token}
            accept_types='image/*, video/*'
            />

            {blogData['header_img'] &&
              <>
              <label className={classes.label}>Header Image: {ServerConfig.SERVER_URL + blogData["header_img"]}</label><br/>
              <img 
                src={ServerConfig.SERVER_URL + blogData['header_img']} 
                style={{'height':'200px'}}
              />
              </>
            }
          
            <form onSubmit={handleSubmit}>
              <label className={classes.label}>Title</label>
              <input className = {classes.inputBox} type="text" name="title" id="title" value={blogData["title"]} onChange={handleChange}/><br/><br/>

              <label className={classes.label}>Description</label>
              <input className = {classes.inputBox} type="text" name="desc" id="description" value={blogData["description"]} onChange={handleChange}/><br/><br/>

              <br/>

              <label className={classes.label}>Body</label>
              <Editor apiKey='enjyfldvg22pmbipvedfbhpph4ej9g4mcdhzw3auggkq6o42' id = 'body'
                init = {{
                  images_upload_handler: (blobInfo, progress) => example_image_upload_handler(blobInfo, progress, auth.access_token),
                  body_id: 'body',
                  auto_focus : '#body',
                  forced_root_block_attrs: { //default settings for editor 
                    'style': ' font-size: 1.15rem; font-weight: 400; color: #797777; margin-bottom: 1rem;'
                  },
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount nonbreaking',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough forecolor backcolor | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  font_size_formats: '1rem 1.15rem 8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                  nonbreaking_force_tab: true,
                  relative_urls : false,
                  //remove_script_host : true,
                  //document_base_url : `${ServerConfig.SERVER_URL}`,
                  images_reuse_filename: false,
                  automatic_uploads: true,
                  init_instance_callback : async (editor) => {
                    const response = await axios.get(`${ServerConfig.SERVER_URL}/blog/post/${props.postIdToEdit}`, config); //We have to call request this info again so that the tinymce-editor can read it
                    editor.setContent(response.data['body']);
                  },
                  setup: function (editor) {
                    editor.on('change', (e)=>{
                      e.preventDefault();
                      setBlogData((prevData)=>({...prevData, ["body"]:e.target.getContent()}));
                    });
                  },
                }}
              /><br/>

              <Button type = "submit">Submit</Button>
            </form>
          
      </>
    )
}
 
export default AddPost;
