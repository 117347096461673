import React, { useState, useEffect } from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Nav from 'components/Nav/Nav'
import UpdatedFooter from 'components/Footer/UpdatedFooter.js'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden'
  },
  main: {
    flexGrow: 1,
    marginTop: 120
  },
}))

const Page = (props) => {
  const classes = useStyles()

  useEffect(() => {
    document.title = "RecycletekStaff|"+props.title
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.main}>{props.children}</div>
      <UpdatedFooter />
    </div>
  )
}

export default Page

Page.propTypes = {
  children: PropTypes.node
}
