import { Roles } from 'config'

import AdminPage from 'views/AdminPage/AdminPage.js'
import PublicRelationsPage from 'views/PublicRelations/PublicRelationsPage.js'
import DashboardPage from 'views/DashboardPage/DashboardPage.js'
import AIPage from 'views/AIPage/AIPage.js'
import PickupPage from 'views/Pickups/PickupPage.js'
import EcoCubePage from 'views/EcoCubePage/EcoCubePage.js'
import AccountingPage from 'views/AccountingPage/AccountingPage.js'
import ShopPage from 'views/ShopPage/ShopPage.js'

export default [
  {
    component: DashboardPage,
    path: '/',
    title: 'Dash',
    exact: true,
  },
  // pickups suspended as of May 2024
  /*{
    component: PickupPage,
    path: '/pickup',
    title: 'Pickups',
    exact: true,
    permission: [
      Roles.DRIVER,
      Roles.ADMIN
    ],
  },*/
  {
    component: AdminPage,
    path: '/admin',
    title: 'Admin',
    exact: true,
    permission: [
      Roles.ADMIN
    ],
  },
  {
    component: AIPage,
    path: '/ai',
    title: 'AI',
    exact: true,
    permission: [
      Roles.ADMIN
    ],
  },
  {
    component: PublicRelationsPage,
    path: '/publicrelations',
    title: 'PR',
    exact: true,
    permission: [
      Roles.ADMIN
    ],
  },
  /*{
      component: EcoCubePage,
      path: '/ecocubes',
      title: 'Ecocubes',
      permission: [
          Roles.ADMIN
      ]
  },*/
  {
    component: AccountingPage,
    path: '/accounting',
    title: 'Accounting',
    permission: [
      Roles.ADMIN
    ]
  },
  {
    component: ShopPage,
    path: '/shop',
    title: 'Shop',
    permission: [
      Roles.ADMIN
    ]
  },
  /*
  {
    component: Blog,
    path: '/blogHome',
    title: 'Blog',
    permission: [
      Roles.ADMIN
    ]
  },*/
]
