import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { ServerConfig } from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

import loading_gif from '../../assets/img/loading.gif'

import SortableTbl from "react-sort-search-table";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({}))


const PerMachine = () => {
  const token = useSelector(state => state.auth.access_token)

  const [availableMachines, setAvailableMachines] = useState([])
  const [selectedMachine_id, setSelectedMachine_id] = useState(0)
  const [machineTransactions, setMachineTransactions] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeZone, setTimeZone] = useState("America/New_York");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles()
  async function fetchMachineList(token) {
    const config = { headers: { 'Content-Type': 'application/json' } }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/machines`, config)
    setAvailableMachines(results.data)
  }


  async function fetchMachineTransactions(token) {
    console.log("Updating machine tasks")
    setIsLoading(true);

    const config = { headers: { 'Content-Type': 'application/json' } }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    let this_startdate = (1900 + startDate.getYear()) + "-" + (1 + startDate.getMonth()) + "-" + (startDate.getDate())
    let this_enddate = (1900 + endDate.getYear()) + "-" + (1 + endDate.getMonth()) + "-" + (endDate.getDate())

    const results = await axios.post(`${ServerConfig.SERVER_URL}/task/list`, { machine_id: selectedMachine_id, timezone: timeZone, date: this_startdate, enddate: this_enddate }, config)

    for (let idx in results.data) {
      if (results.data[idx].id !== undefined) {
        console.log("Querying for task with id " + results.data[idx].id)
        const res = await axios.post(`${ServerConfig.SERVER_URL}/task/get_task_stats`, { 'task_id': results.data[idx].id }, config);
        _.extend(results.data[idx], res.data);
        // calculate human-readable date-time
        //let datetime = results.data[idx].end_ts;
        let dollarvalue = "$" + (results.data[idx].redemption_amount_cents / 100.0).toFixed(2);

        let datetime = new Intl.DateTimeFormat('en-US',
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: timeZone,
            timeZoneName: "short"
          }
        ).format(new Date(results.data[idx].end_ts));
        _.extend(results.data[idx], { 'datetime': datetime, 'redemption_amount_dollar': dollarvalue });
      }
    }

    setMachineTransactions(results.data)
    setIsLoading(false);
  }


  useEffect(() => {
    fetchMachineList(token)
  }, [token])

  useEffect(() => {
    fetchMachineTransactions(token);

    const interval = setInterval(() => { fetchMachineTransactions(token); }, 5 * 60 * 1000); // 5 minutes
    return () => clearInterval(interval);

  }, [selectedMachine_id, startDate, endDate, timeZone, token])

  if (!token) {
    return <Redirect to="/login" />
  }

  function formatTransaction(mt) {
    return <Typography>{mt.customer_id}: {mt.transaction_details.count_alu_lt_24oz}</Typography>
  }

  let col = ["datetime", "customer_name",
    "count_aluminum_lt_24oz", "count_aluminum_geq_24oz",
    "count_bi-metal_lt_24oz", "count_bi-metal_geq_24oz",
    "count_pete_lt_24oz", "count_pete_geq_24oz",
    "count_hdpe_lt_24oz", "count_hdpe_geq_24oz",
    "count_ldpe_lt_24oz", "count_ldpe_geq_24oz",
    "count_glass_lt_24oz", "count_glass_geq_24oz",
    "redemption_amount_dollar"];
  let tHead = ["Date/Time", "Customer",
    "#ALU <24oz", "#ALU >=24oz",
    "#Bi-M <24oz", "#Bi-M >=24oz",
    "#PETE <24oz", "#PETE >=24oz",
    "#HDPE <24oz", "#HDPE >=24oz",
    "#LDPE <24oz", "#LDPE >=24oz",
    "#GL <24oz", "#GL >=24oz",
    "Total"];

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={3} style={{ 'margin-bottom': '20px', 'padding-right': '20px' }}>
          <Typography variant="h2">
            Machine:
          </Typography>

          <select
            className="form-select"
            name="machine"
            id="machine"
            onChange={(event) => { setSelectedMachine_id(event.target.value) }}>
            {_.map(availableMachines, (am, id) => {
              return <option value={am.id}>{am.name}</option>
            })}
          </select>

        </Grid>

        <Grid xs={3} style={{ 'margin-bottom': '20px', 'padding-right': '20px' }}>
          <Typography variant="h2">
            Timezone
          </Typography>
          <select
            className="form-select"
            name="timezone"
            id="timezone"
            onChange={(event) => { setTimeZone(event.target.value) }}>
            <option value="America/New_York">New York</option>
            <option value="America/Los_Angeles">California</option>
          </select>
        </Grid>

        <Grid xs={3} style={{ 'margin-bottom': '20px' }}>
          <Typography variant="h2">
            Date:
          </Typography>
          <DatePicker selected={startDate} onChange={(date) => { setStartDate(date) }}
            className="form-select" />
        </Grid>

        <Grid xs={3} style={{ 'margin-bottom': '20px' }}>
          <Typography variant="h2">
            Date:
          </Typography>
          <DatePicker selected={endDate} onChange={(date) => { setEndDate(date) }}
            className="form-select" />
        </Grid>


        <Grid xs={12} style={{ 'text-align': 'center' }}>
          {!isLoading &&
            <SortableTbl paging={false} defaultCSS={false}
              tblData={machineTransactions} tHead={tHead} dKey={col} search={false} />
          }
          {isLoading &&
            <img src={loading_gif} style={{ 'width': '150px' }} />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default PerMachine
