import React from 'react'
import {ServerConfig} from 'config'

import List from '../../components/EntryListAndEdit/List'


const Employees = () => {
  const editable_fields = [
    ["id_tenant", "Tenant id"],
    ["first_name", "First name"],
    ["last_name", "Last name"],
    ["street", "Street"],
    ["zipcode", "Zipcode"],
    ["city", "City"],
    ["state", "State"],
    ["email", "Email"],
    ["phone_number", "Phone number"],
    ["password", "Password"]
  ];

	return (
		<>
		    <List 
          list_get_url = {`${ServerConfig.SERVER_URL}/tenant/employees/`}
          detail_get_url = {`${ServerConfig.SERVER_URL}/tenant/employees/details/`}
          detail_put_url = {`${ServerConfig.SERVER_URL}/tenant/employees/`}
          add_new_url = {`${ServerConfig.SERVER_URL}/tenant/employees/`}

          column_names = {["id_tenant", "first_name", "last_name", "email", "phone_number", "edit"]}
          header_names = {["Tenant ID", "First Name", "Last Name", "Email", "Phone", ""]}

          optional_column_names={["zipcode", "city", "housenumber", "street"]}
          optional_header_names={["Zip", "City", "Hnr", "Street"]}

          editable_fields = {editable_fields}
        />
		</>
		)
}

export default Employees
