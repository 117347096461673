import React, { useState, useEffect, useReducer } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import DatePicker from 'react-date-picker';

// components
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
  label: {
    display:'inline-block',
    width:'200px',
    textAlign:'right',
    marginRight: '15px',
    verticalAlign: 'top',
  },
  explanation:{
    fontColor:'lightgray',
    fontSize:'12px',
    paddingLeft:'10px'
  },
  desc: {
    display:'inline-block',
    width:'450px',
    textAlign:'left'
  },
}))

const AvailablePickupDays = () => {
  const token = useSelector(state => state.auth.access_token)
  const [availablePickupdays, setAvailablePickupdays] = useState([])
  const classes = useStyles()

  const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(event){
    event.preventDefault();

    const config = {headers:{'Content-Type':'application/json'}}
    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }

    setSubmitting(true);

    const results = await axios.post(`${ServerConfig.SERVER_URL}/recurring_pickup/available_pickup_days`, formData, config)
    console.log(results.data)
    setAvailablePickupdays(results.data)
  }

  const handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setFormData({
      name: event.target.name,
      value: value,
    });
  }

  useEffect(() => {
    const fetchCustomerList = async () => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/recurring_pickup/available_pickup_days`, config)
      setAvailablePickupdays(results.data)
      console.log(availablePickupdays)
    }

    fetchCustomerList()
  }, [])

  if(!token){
    return <Redirect to="/login" />
  }

  return (
    <>
      <Typography variant="h2">
        Available Pickup Days:
      </Typography>

      {submitting && <div>
            You are submitting the following:
            <ul>
              {Object.entries(formData).map(([name, value]) => (
                <li key={name}><strong>{name}</strong>:{value.toString()}</li>
              ))}
            </ul>
          </div>
          } 
      
      <Card>
        <CardContent>
        <Typography variant="h3">Insert new</Typography>
          <form onSubmit={handleSubmit}>
            <label className={classes.label} htmlFor="startdate">Startdate</label>
            <input type="text" name="startdate" value={formData.startdate || ''} onChange={handleChange} /> 
            <span className={classes.explanation}>Format: YYYY-MM-DD</span><br/>

            <label className={classes.label} htmlFor="until">Enddate</label>
            <input type="text" name="until" value={formData.until || ''} onChange={handleChange} /> 
            <span className={classes.explanation}>Format: YYYY-MM-DD</span><br/>

            <label className={classes.label} htmlFor="frequency">Frequency</label>
            <select name="frequency" value={formData.frequency || ''} onChange={handleChange}>
              <option value="DAILY">DAILY</option>
              <option value="WEEKLY">WEEKLY</option>
              <option value="MONTHLY">MONTHLY</option>
            </select><br />
          
            <label className={classes.label} htmlFor="interval">Interval</label>
            <input type="text" name="interval" value={formData.interval || ''} onChange={handleChange} /> 
            <span className={classes.explanation}>1|2|3|...</span><br/>

            <label className={classes.label} htmlFor="zipcode">Zipcode</label>
            <input type="text" name="zipcode" value={formData.zipcode || ''} onChange={handleChange} /> <br/>

            <label className={classes.label} htmlFor="bulk">Bulk</label>
            <input type="checkbox" name="bulk" value={formData.bulk || ''} onChange={handleChange} /> <br/>

            <label className={classes.label} htmlFor="curbside">Curbside</label>
            <input type="checkbox" name="curbside" value={formData.curbside || ''} onChange={handleChange} /> <br/>

            <label className={classes.label} htmlFor="restricted_to_customer">Restricted to Customer</label>
            <input type="text" name="restricted_to_customer" value={formData.restricted_to_customer || ''} onChange={handleChange} /> 
            <span className={classes.explanation}>If yes, insert Customer-id, if no leave empty</span><br/>

            <button type="submit">Save</button>
          </form>
        </CardContent>
      </Card> <br/>
            
      {/*
        __tablename__ = 'availablepickupdays'
        id = db.Column(db.Integer, primary_key=True, autoincrement=True)
        startdate = db.Column(db.Date)
        until = db.Column(db.Date)
        frequency = db.Column(db.String, nullable=False)
        interval = db.Column(db.Integer, nullable=False)
        zipcode = db.Column(db.String, db.ForeignKey('serviceable_zipcodes.zipcode'), nullable=False)
        bulk = db.Column(db.Boolean, default=False)
        curbside = db.Column(db.Boolean, default=False)
        restricted_to_customer = db.Column(db.String, db.ForeignKey('customer.id'), default=None)
      */}
      

      {_.map(availablePickupdays, (p) => {
            return <>
            <Card>
              <CardContent>
                <Typography variant="h3">Pickup-Day</Typography>
                <span className={classes.label}>Startdate:</span> <div className={classes.desc}>{p.startdate}</div> <br />
                <span className={classes.label}>Until:</span> <div className={classes.desc}>{p.until}</div> <br />
                <span className={classes.label}>Frequency:</span> <div className={classes.desc}>{p.frequency}</div> <br />
                <span className={classes.label}>Interval:</span> <div className={classes.desc}>{p.interval}</div> <br />
                <span className={classes.label}>Zipcode:</span> <div className={classes.desc}>{p.zipcode}</div> <br />
                <span className={classes.label}>Bulk:</span> <div className={classes.desc}>{p.bulk ? 'Yes' : "No"}</div> <br />
                <span className={classes.label}>Curbside:</span> <div className={classes.desc}>{p.curbside ? 'Yes' : "No"}</div> <br />
                <span className={classes.label}>Customer:</span> <div className={classes.desc}>{p.restricted_to_customer}</div> <br />
              </CardContent>
            </Card>
              </>
        })
      }
    </>
  )
}

export default AvailablePickupDays