import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))


const Orders = () => {
    const token = useSelector(state => state.auth.access_token)
  
    const [orders_pending, setOrdersPending] = useState([])
    const [orders_shipping, setOrdersShipping] = useState([])
    const [orders_done, setOrdersDone] = useState([])
  
    const classes = useStyles()
  
    useEffect(() => {
      const fetchOrderList = async () => {
        const config = {headers:{'Content-Type':'application/json'}}
  
        if(token){
          config.headers.Authorization = `Bearer ${token}`
        }
  
        const results = await axios.get(`${ServerConfig.SERVER_URL}/shop/orders`, config)
        
        console.log(results.data)
        
        setOrdersPending(results.data.orders_pending)
        setOrdersShipping(results.data.orders_shipping)
        setOrdersDone(results.data.orders_done)
      }
  
      fetchOrderList()
    }, [])
  
    if(!token){
      return <Redirect to="/login" />
    }
  
    async function changeState(id, newstate){
      console.log("CHANGESTATE CALLED for order id "+id+": state set to "+newstate)
      const config = {headers:{'Content-Type':'application/json'}}
      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }
      const results = await axios.post(`${ServerConfig.SERVER_URL}/shop/orderstatus`, {id_order: id, status: newstate}, config)
      console.log(results.data)
      setOrdersPending(results.data.orders_pending)
      setOrdersShipping(results.data.orders_shipping)
      setOrdersDone(results.data.orders_done)
    }

    function display_orders(orders){
        {_.map(orders, (o,id) => {
            return display_order(o,id)
        })
      }
    }

    function display_order(o,id){
        return <>
                      <Card>
                          <CardContent>
                      Order ID {id} ({o.products.length} products)<br />
                    <ListGroup className={classes.listGroup}>
                    {_.map(o.products, single_product => {
                        return <ListGroupItem className={classes.listItem}>
                            {single_product.count} x {single_product.name},&nbsp;<br/><br />
                            {o.shipto_name} <br/>
                            {o.shipto_street} <br/>
                            {o.shipto_city}<br /><br /> 
                            Shipping label: {o.shipping_label}<br />
                            Dropoff-Id: {o.dropoff_id} <br/><br/>
                            <button onClick={() => changeState(id, "pending")} disabled={o.status == "pending"}>Pending</button>
                            <button onClick={() => changeState(id, "shipping")} disabled={o.status == "shipping"}>Shipping</button>
                            <button onClick={() => changeState(id, "done")} disabled={o.status == "done"}> Done</button>
                    </ListGroupItem>
                        })}
                    </ListGroup>
                    </CardContent>
                    </Card>
                    <br />
                    </>
    }
  
    return (
      <>
                <Typography variant="h2">
                  Orders:
                </Typography>

                {_.map(orders_pending, (o,id) => {
                    return display_order(o,id)
                })}
  
                <Typography variant="h2">
                  Orders in shipping:
                </Typography>
  
                {_.map(orders_shipping, (o,id) => {
                    return display_order(o,id)
                })}   
  
                <Typography variant="h2">
                  Orders finished:
                </Typography>
  
                {_.map(orders_done, (o,id) => {
                    return display_order(o,id)
                })}   
            </>
    )
  }
  
  export default Orders
