// Importing modules
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import {useSelector} from 'react-redux';
// @material-ui/core components
import { makeStyles, } from '@material-ui/core/styles'
import { Typography, Button, Grid, Box, Modal, Container } from '@material-ui/core'
import SortableTbl from "react-sort-search-table";
import {ServerConfig} from 'config'

import AddPost from './AddBlogPost';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from 'components/Page/Page';

const evt = new Event('blogpost_table_is_dirty');

let refresh = true; //means a refresh is needed

//Styles
const useStyles = makeStyles((theme) => ({
    container: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(30),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(12)
      }
    },
    listItem: {
      fontSize:'15px',
      listStyle:'none',
      margin:0,
      padding:0
    },
    listGroup: {
      margin:0,
      padding:0,
      marginBottom:20,
    },
    description: {
        marginLeft: '20px',
    },
    blogName: {
        display: 'inline-Block',
        marginRight: '20px',
    },
    button: {
      border: '0px',
      borderRadius: '15px',
      width: '110px',
      height: '40px',
      lineHeight: '1.75',
      fontWeight: '600',
      fontSize: '15px',
      color: 'white',
      backgroundColor: '#97D700',
    },
    warning: {
      color: 'red',
    }
  }))

const DeletePostPage = (props) => {
  const auth = useSelector(state => state.auth);
  const [post, setPost] = useState([]);
  const classes = useStyles()
  
  const fetchPost = async () =>{
    const config = {headers:{'Content-Type':'application/json'}}
    if(auth.access_token){
      config.headers.Authorization = `Bearer ${auth.access_token}`
    }
    console.log("PROP ID: ", props.id);
    const response = await axios.get(`${ServerConfig.SERVER_URL}/blog/post/${props.id}`, config);
    setPost(response.data);
  }
    useEffect(() => {
        fetchPost();
    },[])
    
    // Deletes the post
    const deletePost = async (post_id) => {
      const config = {headers:{'Content-Type':'application/json'}}
        if(auth.access_token){
          config.headers.Authorization = `Bearer ${auth.access_token}`
        }

      try {
          const response = await axios.post(`${ServerConfig.SERVER_URL}/blog/delete_post`, {'id':post_id}, config);
          document.getElementById('deleted-post-status').innerHTML = "Post has been deleted succesfully";
          document.getElementById('deleted-message').innerHTML = "Post has been deleted succesfully";
          window.dispatchEvent(evt); // dispatch event to tell the "list-component" to fetch the post-list again
          //alert(response.data["msg"]);
      } catch (error) {
          console.log(error);
      }
    };
  
    return (
    <>
        <Container maxWidth="lg" className={classes.container}>
          <Card>
            <CardContent>
              <Typography id="deleted-post-status" variant="h6" component="h2" style={{color: "red"}}>
                WARNING: THIS WILL BE PERMANENTLY DELETED!  
              </Typography>
                <hr/>
                <h3 id = "deleted-message">Are You Sure You Want To Delete "{post.title}"?</h3>
                <br style={{'clear':'both'}}/>
                <Button onClick={() => {deletePost(props.id)}} style={{'margin':'10px', 'display':'block', 'float':'left', 'backgroundColor': 'red'}}>Delete</Button>
                <Button type='submit' onClick={() => {props.callback_close()}} style={{'margin':'10px', 'display':'block', 'float':'left', 'backgroundColor':'darkgray'}}>Cancel</Button>
            </CardContent>
          </Card>
        </Container>
    </>
    );
}

const DeletePostComponent = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true); refresh = false;}
  const handleClose = () => {setOpen(false); refresh = true;}
  
  const { id } = props;
  console.log("The ID: ", id);
  // only load data when the model is shown
  useEffect(() => {},[open]);
  
  return (
    <>
      <p onClick={handleOpen} style={{'float':'left', 'cursor':'pointer', 'color':'#900'}}>Delete</p>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box style={{'maxHeight':'100vh', 'overflowY':'auto'}}>
          <DeletePostPage id={id} callback_close={handleClose} />
        </Box>
      </Modal>
  </>
  );
};

  const DisplayPosts = (props)=>{
    const auth = useSelector(state => state.auth)
    const classes = useStyles()
    const [posts, setPosts] = useState([]) 
    const [postIdToEdit, setPostIdToEdit] = useState(undefined) 

    const fetchPosts = async () =>{
      const config = {headers:{'Content-Type':'application/json'}}
      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }
      const response = await axios.get(`${ServerConfig.SERVER_URL}/blog/posts`,config);
      setPosts(response.data);
    }
    window.addEventListener('blogpost_table_is_dirty', fetchPosts);
    
    useEffect(() => {
        if(refresh){
          fetchPosts();
        }
    }, []);
    
    //VERIFICATION
    if(!auth.access_token){
      return <Redirect to="/login" />
    }

    const blogPostsPage = (e)=>{
        if(postIdToEdit !== undefined){ 
            return(
              <>
                <Button onClick={() => {setPostIdToEdit(undefined)}}>Back</Button><hr/>
                <AddPost postIdToEdit={postIdToEdit}/>
              </>
            ) 
        }
        return(
            <>
              <Typography variant="h2">
                  Blog Post
              </Typography>
              <ul>
                {
                    posts.map((post, index) => (
                        <li>
                        <Grid container spacing={0}>
                          <Grid item xs={2}>
                            <Typography>{post.title}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{post.author_first_name + " " + post.author_last_name}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <p onClick={() => {setPostIdToEdit(post.id)}} style={{'float':'left', 'cursor':'pointer', 'marginRight':'20px', 'color':'#009'}}>Edit</p>
                            <DeletePostComponent id = {post.id} />
                          </Grid>
                        </Grid>
                        </li>
                    ))
                }
              </ul>
            </>
        )
    }
    
    return(
      <>
        {blogPostsPage()}
      </>
    )
}
 
export default DisplayPosts;
