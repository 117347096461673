import React, { useState, useEffect,memo } from 'react'

// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';

// components
import Page from 'components/Page/Page'
import _, { get } from 'lodash'

import { getAllowedRoutes } from 'utils';
import MapAllowedRoutes from 'routes/MapAllowedRoutes';

import EcoCube from './EcoCube.js'
import EcoCubeDetail from './EcoCubeDetail.js'

const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))


const EcoCubePage = ({children}) => {
  
  const auth = useSelector((state) => state.auth)
  const [ecocube_id, set_ecocube_id] = useState('ecocube_id')
  
  const classes = useStyles()

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  return (
    <Page title="Admin">
        <Container maxWidth="lg" className={classes.container}>
          <Grid container direction="row">
            <Grid item xs={12} sm={6}>
              <Typography variant="h1">
                Admin
              </Typography>

              {hasPermission(["Admin","admin"]) &&
                    <EcoCube changefun={set_ecocube_id} />
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <EcoCubeDetail ecocube_id={ecocube_id} />
            </Grid>
          </Grid>
        </Container>
    </Page>
  )
}

export default EcoCubePage

EcoCubePage.propTypes = {
  children: PropTypes.node
}
