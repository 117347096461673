import React, { useState, useEffect, useReducer } from 'react'
import { ServerConfig } from 'config'


// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
//import List from '../../components/EntryListAndEdit/List'
import PaginatedList from '../../components/PaginatedList/PaginatedList'

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

const useStyles = makeStyles((theme) => ({}))


const Labels = () => {
  const non_editable_fields = [
    ["label", "Label"],
  ]

  const editable_fields = [
    ["material_subtype", "Mat"],
    ["size_oz", "oz"],
    ["weight_g", "weight [g]"],
    ["co2_saved_g", "co2 [g]"],
    ["non-crv", "Non-CRV states"]
  ];

  return (
    <>
      <PaginatedList
            list_get_url={`${ServerConfig.SERVER_URL}/admin/ai/labels/`}
            detail_get_url={`${ServerConfig.SERVER_URL}/admin/ai/labels/detail/`}
            detail_put_url={`${ServerConfig.SERVER_URL}/admin/ai/labels/`}

            column_names={["label", "material_subtype", "size_oz", "edit"]}
            header_names={["Label", "Material", "Size [oz]", ""]}

            optional_column_names={["non-crv", "weight_g", "co2_saved_g", "last_updated",]}
            optional_header_names={["NonCRV states", "Weight [g]", "Co2 [g]", "Updated"]}

            editable_fields={editable_fields}
            non_editable_fields={non_editable_fields}
        />
    </>
  )

}

export default Labels