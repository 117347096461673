import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  small: {
    '& .MuiTypography-body1': {
      fontSize: '14px',
    },
    '& .MuiTypography-h5': {
      fontSize: '20px',
    },
  },
  bold: {
    fontWeight: 400,
  },
  locationTitle: {
    fontWeight: 300,
  },
  service: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginTop: '2px',
    marginLeft: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  thin: {
    fontWeight: 300,
  },
  dayHours: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& :nth-child(2)': {
      minWidth: '77%',
    },
  },
  column: {
    fontWeight: 300,
    flex: '1',
  },
}));

function LocationCard(props) {
  const classes = useStyles();
  const { ecocenter } = props;

  return (
    <Box className={props.size === 'small' && classes.small} component="div">
      <Box mb={2}>
        <Typography
          className={classes.locationTitle}
          variant="h5"
          color="primary"
        >
          <span className={classes.bold}>Center</span>
        </Typography>
        <Box className={classes.service}>
          <Typography color="primary">
            Service
          </Typography>
          <Box className={classes.icon}>{ecocenter.icon}</Box>
        </Box>
      </Box>
      {/* Display the following data if ecocenter props are passed down */}
      {ecocenter && (
        <>
          <Box mb={1} component="div">
            <Typography color="primary">Address</Typography>
            {props.size !== 'small' && (
              <Typography color="primary">
                city, state zipcode
              </Typography>
            )}
            {props.size === 'small' && (
              <Typography color="primary">
                city, state
              </Typography>
            )}
          </Box>
          {/* {props.size !== 'small' && ( */}
          <>
            {ecocenter.phone && (
              <Box mb={1} component="div">
                <Typography color="primary">{t('locations.phone')}</Typography>
                <Box ml={1}>
                  <Typography className={classes.thin}>
                    phone
                  </Typography>
                </Box>
              </Box>
            )}
            {ecocenter.hours && (
              <Box component="div">
                <Typography color="primary">hours</Typography>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    location mon
                  </Typography>
                  <Typography className={classes.column}>
                    {t(ecocenter.hours[0])}
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    locationstrue
                  </Typography>
                  <Typography className={classes.column}>
                    hours1
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    {t('locations.wed')}
                  </Typography>
                  <Typography className={classes.column}>
                    hours2
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    {t('locations.thu')}
                  </Typography>
                  <Typography className={classes.column}>
                    hours3
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    {t('locations.fri')}
                  </Typography>
                  <Typography className={classes.column}>
                    hours4
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    {t('locations.sat')}
                  </Typography>
                  <Typography className={classes.column}>
                    hours5
                  </Typography>
                </Box>
                <Box className={classes.dayHours}>
                  <Typography className={classes.column}>
                    {t('locations.sun')}
                  </Typography>
                  <Typography className={classes.column}>
                    hours6
                  </Typography>
                </Box>
              </Box>
            )}
          </>
          {/* )} */}
        </>
      )}
    </Box>
  );
}

export default LocationCard;
