import React from 'react'
import {ServerConfig} from 'config'

import PaginatedList from '../../components/PaginatedList/PaginatedList'


const Tenants = () => {
  const editable_fields = [
    ["password", "Password"],
    ["first_name", "First name"],
    ["last_name", "Last name"],
    ["business_name", "Business name"],
    ["shortcode", "Shortcode"],
    ["email", "Email"],
    //["paypal_email", "Paypal Email"],
    ["phone_number", "Phone number"],
    ["street", "Street"],
    //["housenumber", "Housenumber"],
    ["zipcode", "Zipcode"],
    ["city", "City"],
    ["state", "State"]
  ];

	return (
		<>
		    <PaginatedList
          list_get_url = {`${ServerConfig.SERVER_URL}/admin/tenant/`}
          detail_get_url = {`${ServerConfig.SERVER_URL}/tenant/`}
          detail_put_url = {`${ServerConfig.SERVER_URL}/tenant/`}
          add_new_url={`${ServerConfig.SERVER_URL}/tenant/`}

          column_names = {["balance_dollars", "business_name", "shortcode",  "edit"]}
          header_names = {["Balance", "Business Name", "Shortcode", ""]}

          optional_column_names={["zipcode", "city", "street", "email", "phone_number"]}
          optional_header_names={["Zip", "City", "Street", "Email", "Phone"]}

          editable_fields = {editable_fields}

          ledger_get_url = '/ledger/tenant/'  // the endpoints of the detail-view are NOT adapted for tenants yet!
          ledger_allow_entries = {false}
        />
		</>
		)
}

export default Tenants
