// AUTH TYPES export const
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const LOGOUT = "LOGOUT";
export const USER_LOADING = "USER_LOADING";
export const LOAD_USER_FROM_STORAGE = "LOAD_USER_FROM_STORAGE";

// VERIFICATION TYPES
export const CUSTOMER_SMS_SENT = "CUSTOMER_SMS_SENT";
export const CUSTOMER_CODE_VERIFIED = "CUSTOMER_CODE_VERIFIED";
export const CHECKING_CUSTOMER_CODE = "CHECKING_CUSTOMER_CODE";
export const CUSTOMER_2FA_EMAIL_SENT = "CUSTOMER_2FA_EMAIL_SENT";
// CUSTOMER types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_CUSTOMER = "REGISTER_CUSTOMER";
export const UNIQUE_CUSTOMER = "UNIQUE_CUSTOMER";
export const NOT_UNIQUE_CUSTOMER = "NOT_UNIQUE_CUSTOMER";

// ALERT types
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_FAIL = "ALERT_FAIL";
export const ALERT_WARNING = "ALERT_WARNING";
export const CLOSE_ALERT = "CLOSE_ALERT";

// TRANSACTION types
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const PROCESSING_TRANSACTION = "PROCESSING_TRANSACTION";
