import React from 'react'
import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'


const RefundRates = () => {
  const editable_fields = [
    ["id", "ID", "str"],
    ["state", "State", "str"],
    ["validity_start", "Validity Start", "date"],
    ["validity_end", "Validity End", "date"],
    ["material", "Material", "str"],
    ["microdollar_per_pound", "µ$/lbs", "int"],
    ["daily_weight_limit_lbs", "Limit lbs/day", "int"]
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/admin/refundrates`}
        detail_get_url={`${ServerConfig.SERVER_URL}/admin/refundrates/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/admin/refundrates/`}
        add_new_url={`${ServerConfig.SERVER_URL}/admin/refundrates`}

        column_names={["id", "state", "validity_start", "validity_end", "material", "microdollar_per_pound", "daily_weight_limit_lbs", "edit"]}
        header_names={["ID", "state", "Valid from", "Valid until", "Material", "µ$/lbs", "Limit lbs/day", ""]}

        editable_fields={editable_fields}
      />
    </>
  )
}

export default RefundRates
