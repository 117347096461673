import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {useSelector} from 'react-redux'
import {ServerConfig} from 'config'
import axios from 'axios'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// components
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({
  label: {
    display:'inline-block',
    width:'200px',
    textAlign:'right',
    marginRight: '15px',
    verticalAlign: 'top',
  },
  desc: {
    display:'inline-block',
    width:'450px',
    textAlign:'left'
  },
}))

const CustomPickupRequests = () => {
  const token = useSelector(state => state.auth.access_token)
  
  const [customPickupRequests, setCustomPickupRequests] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const fetchCustomerList = async () => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/recurring_pickup/request_custom_pickup`, config)
      setCustomPickupRequests(results.data)
      
    }

    fetchCustomerList()
  }, [])

  if(!token){
    return <Redirect to="/login" />
  }

  async function changeState(id, newstate){
    console.log("CHANGESTATE CALLED for custom pickup request with id "+id+": state set to "+newstate)
    const config = {headers:{'Content-Type':'application/json'}}
    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }
    const results = await axios.post(`${ServerConfig.SERVER_URL}/recurring_pickup/request_custom_pickup_status`, {id: id, status: newstate}, config)
    console.log(results.data)
    setCustomPickupRequests(results.data)
  }

  function display_custom_request(p){
    return <>
            <Card>
              <CardContent>
                <Typography variant="h3">Custom Pickup-Request</Typography>
                <span className={classes.label}>Name:</span> <div className={classes.desc}>{p.first_name} {p.last_name}</div> <br />
                <span className={classes.label}>Address:</span> <div className={classes.desc}>{p.street} {p.housenumber}, {p.zipcode} {p.city}</div> <br />
                <span className={classes.label}>Email:</span> <div className={classes.desc}>{p.email}</div> <br />
                <span className={classes.label}>Phone:</span> <div className={classes.desc}>{p.phone_number}</div> <br />
                <span className={classes.label}>Customer-ID</span> <div className={classes.desc}>{p.id_customer}</div><br />
                <hr />
                <span className={classes.label}>Days:</span> <div className={classes.desc}>{p.pickupdays}</div> <br />
                <span className={classes.label}>Frequency:</span> <div className={classes.desc}>{p.frequency}</div> <br />
                <span className={classes.label}>Num Containers:</span> <div className={classes.desc}>{p.number_of_containers}</div> <br />
                <span className={classes.label}>Additional Info:</span> <div className={classes.desc}>{p.additional_info}</div> <br />

                <button onClick={() => changeState(p.id, "pending")} disabled={p.status == "pending"}>Pending</button>
                <button onClick={() => changeState(p.id, "done")} disabled={p.status == "done"}> Done</button>
              </CardContent>
            </Card>
            <br />
          </>
  }

  return (
    <>
      <Typography variant="h2">
        Custom Pickup Requests (pending):
      </Typography>

      {_.map(customPickupRequests, (p) => {
        if (p.status == "pending"){
          return display_custom_request(p)
        }
        })
      }

      <Typography variant="h2">
        Custom Pickup Requests (done):
      </Typography>

      {_.map(customPickupRequests, (p) => {
        if (p.status == "done"){
          return display_custom_request(p)
        }
        })
      }
    </>
  )
}

export default CustomPickupRequests
