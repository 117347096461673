import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, Modal, Button, Box } from '@material-ui/core'
import { ListGroup, ListGroupItem, Table } from 'reactstrap';

import Form from 'react-bootstrap/Form'

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'


const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
    label: {
        display:'block',
        width:200
    },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Messaging = () => {
  console.log("[PublicRelationsPage] Loading!")
  const auth = useSelector(state => state.auth)
  const classes = useStyles()
  const dispatch = useDispatch();

  useEffect(() => {}, [])

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

    const handleSubmit = async (event) => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

        event.preventDefault();
        const data = new FormData(event.target);
        const value = Object.fromEntries(data.entries());
        const res = await axios.post(`${ServerConfig.SERVER_URL}/admin/send_bulk_sms`, value, config);

        if(res.status==200){
            document.getElementById('form_status').innerHTML = "Sent ("+Date().toLocaleString()+")";
            document.getElementById('body').value = ''
        }else{
            document.getElementById('form_status').innerHTML = "Server error ("+Date().toLocaleString()+")";
        }
    }



    return (
            <>
                <Typography variant="h2">SMS</Typography>
                <Typography variant="h6">WARNING! This will send a SMS to <b>all</b> customers!</Typography>
                <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{'float':'left', 'margin':'10px'}}>
                        <label htmlFor="body" style={{'display':'block','width':150+"px"}}>Message</label>
                        <textarea className="form-control" name="body" id="body" onChange={() => {document.getElementById('form_status').innerHTML = (160-document.getElementById('body').value.length)+" chars left"}} style={{'width':'400px', 'height':'300px'}}></textarea>
                    </div>
                    <br style={{'clear':'both'}}/>
                    <Button type='submit' style={{'margin':'10px', 'display':'block', 'float':'left'}}>Send</Button>
                    <Typography id="form_status" variant="h6" component="h2" style={{'lineHeight': '36px', 'padding':'10px'}}></Typography>

                </form>
            </>
            )
}

export default Messaging
