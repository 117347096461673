import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

import { Line } from '@reactchartjs/react-chart.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

import SortableTbl from "react-sort-search-table";

const options = {
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
      },
    ],
  },
};



const useStyles = makeStyles((theme) => ({
    container: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12)
    }
  },
  listItem: {
    fontSize:'15px',
    listStyle:'none',
    margin:0,
    padding:0
  },
  listGroup: {
    margin:0,
    padding:0,
    marginBottom:20,
  },
}))


const EcoCubeDetail = ({ecocube_id}) => {
  console.log("[AdminEcocubeDetail] Loading!")
  const auth = useSelector(state => state.auth)
  
  const [ecocubedetails, setEcocubeDetails] = useState([])
  const [plotdata, setPlotdata] = useState([])

  const classes = useStyles()
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchEcocubeDetails = async () => {
      const config = {headers:{'Content-Type':'application/json'}}

      if(auth.access_token){
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/ecocube/${ecocube_id}`, config)


     var theselabels = [];
     var thisdata = []

     // latest timestamp is first in this result-order
     for(var i=results.data.length-1;i>=0;i--){
        theselabels.push(results.data[i].timestamp)
         thisdata.push(results.data[i].value)
     }


      var thesedatasets = [
        {
          label: 'Values',
          data: thisdata,
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgba(255, 99, 132, 0.2)',
          yAxisID: 'y-axis-1',
        }]

        var thisplotdata = {
            labels: theselabels,
            datasets: thesedatasets
        }


        setPlotdata(thisplotdata)

      setEcocubeDetails(results.data)
    }

    fetchEcocubeDetails()
  }, [ecocube_id])


  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  let col = ["timestamp", "value"];
	let tHead = ["Timestamp", "Value"];

	return (
		<>
      <Typography variant="h2">
            Details for ecocube {ecocube_id}:
      </Typography>


      <Line data={plotdata} options={options} />

    <br />

      <SortableTbl
            paging={false}
            defaultCSS={false}
                tblData={ecocubedetails}
                tHead={tHead}
                customTd={[]}
                dKey={col}
                search={false}
      />
    </>
    )
}

export default EcoCubeDetail
