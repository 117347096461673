import React, { useState, useEffect, memo } from 'react'

// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ServerConfig } from 'config'
import axios from 'axios'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Box from "@material-ui/core/Box";

// components
import Page from 'components/Page/Page'
import _, { get } from 'lodash'

import Customers from './Customers.js'
import Logs from './Logs.js'
import Machines from './Machines.js'
import Employees from './Employees.js'
import Tenants from './Tenants.js'
import TenantEmployees from './TenantEmployees.js'
import RefundRates from './RefundRates.js'
import IFSStats from './IFSStats.js'
import BagStickerMap from './BagStickerMap.js'

const useStyles = makeStyles((theme) => ({ }))

const AdminPage = ({ children }) => {
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  if (!auth.access_token) {
    return <Redirect to="/login" />
  }

  function hasPermission(perm) {
    for (var i = 0; i < auth.permissions.length; i++) {
      if (auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]) {
        return true
      }
    }
    return false
  }

  return (
    <Page title="Admin">
      <Container maxWidth={false}>

        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable">
            <Tab label="Customers" />
            <Tab label="Logs" />
            {/* <Tab label="IFSStats" /> */}
            <Tab label="Machines" />
            <Tab label="Employees" />
            <Tab label="Tenants" />
            <Tab label="Tenant-Employees" />
            <Tab label="BagStickerMap" />
            <Tab label="RefundRates" />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {hasPermission(["Admin", "admin"]) &&
            <Customers />
          }
        </TabPanel>

        <TabPanel value={value} index={1}>
          {hasPermission(["Admin", "admin"]) &&
            <Logs />
          }
        </TabPanel>

        {/*<TabPanel value={value} index={2}>
          {hasPermission(["Admin", "admin"]) &&
            <IFSStats />
          }
        </TabPanel>*/}


        <TabPanel value={value} index={2}>
          {hasPermission(["Admin", "admin"]) &&
            <Machines />
          }
        </TabPanel>

        <TabPanel value={value} index={3}>
          {hasPermission(["Admin", "admin"]) &&
            <Employees />
          }
        </TabPanel>

        <TabPanel value={value} index={4}>
          {hasPermission(["Admin", "admin"]) &&
            <Tenants />
          }
        </TabPanel>

        <TabPanel value={value} index={5}>
          {hasPermission(["Admin", "admin"]) &&
            <TenantEmployees />
          }
        </TabPanel>

        <TabPanel value={value} index={6}>
          {hasPermission(["Admin", "admin"]) &&
            <BagStickerMap />
          }
        </TabPanel>

        <TabPanel value={value} index={7}>
          {hasPermission(["Admin", "admin"]) &&
            <RefundRates />
          }
        </TabPanel>

      </Container>
    </Page>
  )
}

export default AdminPage

AdminPage.propTypes = {
  children: PropTypes.node
}
