import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

import { Line, Bar } from '@reactchartjs/react-chart.js';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, CardHeader } from '@material-ui/core'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Page from 'components/Page/Page'
import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({ }))

const options = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: { zeroLineColor: 'gray', color: 'rgba(128,128,128,0.3)', display: true}
      },
    ],
    xAxes: [{
        type: 'time',
        time: {
            unit: 'day'
        },
        gridLines: { zeroLineColor: 'gray'}
    }]
  },
};



const Plots = () => {
    const token = useSelector(state => state.auth.access_token)
    const [plotdata, setPlotdata] = useState([])

    async function fetchStats(token){
            const config = {headers:{'Content-Type':'application/json'}}
      
            if(token){
              config.headers.Authorization = `Bearer ${token}`
            }
      
            const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/signup_dates`, config)

            var theselabels = [];
             var thisdata = []
    
             for(let i in results.data){
                theselabels.push(i)
                 thisdata.push(results.data[i])
             }

              var thesedatasets = [
                {
                  label: 'Signed up Customers',
                  data: thisdata,
                  fill: false,
                  
                  backgroundColor: 'rgb(0, 152, 220)',
                  borderColor: 'rgba(0, 152, 220, 0.4)',
                  yAxisID: 'y-axis-1',
                }]

                var thisplotdata = {
                    labels: theselabels,
                    datasets: thesedatasets
                }

                setPlotdata(thisplotdata);
      }
  
    const classes = useStyles()
  
    useEffect(() => {
        fetchStats(token);  // initial fetch

        const interval = setInterval(() => { fetchStats(token);}, 5*60*1000); // 5 minutes
        return () => clearInterval(interval);
    }, [token])

  
    if(!token){
      return <Redirect to="/login" />
    }

  
    return (
      <>
              <Card variant="outlined" className={classes.plotClass} style={{width:"100%", float:'left', marginTop:'20px'}}>
	      <CardContent>
                    Signed-up Customers <hr/>
                    <div style={{position:'relative', width:'100%'}}>
                        <Line data={plotdata} options={options} resizeDelay={0} />
                    </div>
                </CardContent>
            </Card>
      </>
    )
  }
  
  export default Plots
